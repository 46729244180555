import {AxiosClient} from "@/common/AxiosClient";
import type {HttpClient} from "@/common/HttpClient";
import {ServerOptions} from "vue3-easy-data-table";
import axios from "axios";

export interface ChargeBackRequest {
    authorization_uuid: string;
    amount: number;
}

export interface ChargeBackResponse {
    status_code: string;
    message: string;
}

export type OrdersList = [Order[], number];

export interface Order {
    id: number;
    type: string;
    amount: number;
    description: string;
    lifetime: number;
    reusability: number;
    authorizationsSuccessCount: number;
    authorizationsFailedCount: number;
    created_at: string;
    commerce: Commerce;
}

export interface OrderById {
    id: number;
    type: string;
    token_uuid: string;
    token_number: string;
    amount: number;
    description: string;
    lifetime: number;
    reusability: number;
    authorizationsSuccessCount: number;
    authorizationsFailedCount: number;
    created_at: string;
    updated_at: string;
    x_url_complete: string;
    x_commerce_email: string;
    commerce: Commerce;
    subscription?: {
        id: number;
        last_payment: number;
        subscription_uuid: string;
        payment_date: string;
    }
}

export type AuthorizationList = [Authorization[], number];

export interface Authorization {
    id: number;
    authorization_uuid: string;
    chargeback_uuid: string;
    chargeback_amount: string;
    status_code: string;
    response_status: number;
    is_confirmed: boolean;
    callback_notified_at: string;
    created_at: string;
    is_chargeback_enabled: boolean;
}

export interface CommerceTree {
    name: string;
    value: string;
    child: Child[];
}

export interface Child {
    name: string;
    value: string;
}

export interface Commerce {
    rut?: string;
    name: string;
    enroller_user_id?: string;
    is_chargeback_enabled: boolean;
}

export interface CommerceSecret {
    enroller_user_id: string;
    secret: string;
}

export interface BranchRequest {
    x_address: string;
    x_branch_id: string;
    x_branch_name: string;
    x_cash_register_id: string;
    x_cash_register_name: string;
    x_email: string;
    x_lat: string;
    x_lng: string;
    x_root_commerce: number;
}

export interface ApiKeyResponse {
    message: string;
    endpoint: string;
    method: string;
    parameters: Parameters;
}

interface Parameters {
    client_id: string;
    client_secret: string;
    grant_type: string;
}

interface IsDashboardAvailable {
    is_dashboard_enabled: boolean;
}

interface IsActivated {
    activated: boolean;
}

export interface OnboardingRequest {
    account_name?: string;
    account_lastname?: string;
    account_email?: string;
    account_phone?: string;
    account_password?: string;
    credential_repeat_password?: string;
    commerce_tax_id?: string;
    commerce_name?: string;
    commerce_subname?: string;
    commerce_tax_address?: string;
    account_id?: number;
    account_type?: string;
    account_owner_id?: string;
    step?: number;
}

export interface GetEnrollerConfiguration {
    name: string;
    tds_charge: string;
    assets_url: string;
    backend_url: string;
    frontend_url:string;
    branding_color:string;
    modules:Module[];
}

export interface Module{
    code: string;
    name: string;
    type: string;
    title: string;
    child:string;
    description: string;
}

export interface SubscriptionResponse {
    subscriptions: Subscription[];
    operation_uuid: string;
    signature: string;
}

export interface Subscription {
    commerce_name: string;
    detail: string;
    enroller_user_id: string;
    status: string;
    token_uuid: string;
    uuid: string;
}

export type SubscriptionList = [Subscription[], number]

export interface Subscription {
    id: number
    generation_uuid: string
    validation_uuid: string
    authorization_uuid: string
    collector_id: string
    timestamp: string
    created_at: string
    updated_at: string
    order: Order
}

export interface Order {
    type: string
    token_uuid: string
    description: string
    commerce: Commerce
}

export interface Commerce {
    name: string
}

class HttpService {
    constructor(private httpClient: HttpClient) {}

    public async getOrders(serverOptions: ServerOptions, search: any): Promise<OrdersList> {
        const {data}: { data: any } = await this.httpClient.get(`/order`, {
            params: {...serverOptions, search: {...search}},
        });
        return data;
    }

    public async getOrderById(id: string): Promise<OrderById> {
        const {data}: { data: OrderById } = await this.httpClient.get(
            `/order/id/${id}`
        );
        return data;
    }

    public async getOrdersAuthorization(
        id: string,
        serverOptions: ServerOptions,
        search: any
    ): Promise<AuthorizationList> {
        const {data}: { data: AuthorizationList } = await this.httpClient.get(
            `/authorization/${id}`,
            {
                params: {...serverOptions, search: {...search}},
            }
        );
        return data;
    }

    public async getCommerce(id = ''): Promise<CommerceTree[]> {
        const {data}: { data: CommerceTree[] } = await this.httpClient.get(
            `/commerce/${id}`
        );
        return data;
    }

    public async getCommerceSecret(id: string): Promise<CommerceSecret> {
        const {data}: { data: CommerceSecret } = await this.httpClient.get(
            `/commerce/${id}/credentials`
        );
        return data;
    }

    public async getIsActivate(enroller_user_id: string): Promise<IsActivated> {
        const {data}: { data: IsActivated } = await this.httpClient.get(
            `/commerce/${enroller_user_id}/is-activated`
        );
        return data;
    }

    //Reversa
    public async setDiscount(
        request: ChargeBackRequest
    ): Promise<ChargeBackResponse> {
        const {data}: { data: ChargeBackResponse } = await this.httpClient.post(
            `/order/chargeback`,
            request
        );
        return data;
    }

    public async sign(request: any): Promise<string> {
        const {data}: { data: any } = await this.httpClient.post(
            `/order/sign`, request
        );
        return data;
    }

    public async setBranch(request: any): Promise<string> {
        const {data}: { data: any } = await this.httpClient.post(
            `/commerce/branch`,
            request
        );
        return data;
    }

    public async getApiKey(body: {
        x_description: string;
    }): Promise<ApiKeyResponse> {
        const {data}: { data: any } = await this.httpClient.post(
            `/commerce/apikey`,
            body
        );
        return data;
    }

    public async getApiKeyList(): Promise<string> {
        const {data}: { data: any } = await this.httpClient.get(`/commerce/apikey`)
        return data;
    }

    public async updateApiKey(client_id: string): Promise<string> {
        const {data}: { data: any } = await this.httpClient.put(
            `/commerce/apikey/`,
            undefined,
            {
                params: {
                    client_id: client_id,
                },
            }
        );
        return data;
    }

    public async createAccount(body: OnboardingRequest): Promise<any> {
        const {data}: { data: any } = await axios.post(
            `/commerce/account`, body
        )
        return data
    }

    public async updateAccount(body: OnboardingRequest): Promise<any> {
        const {data}: { data: any } = await this.httpClient.put(
            `/commerce/account`, body
        )
        return data
    }

    public async createCommerce(body: OnboardingRequest): Promise<any> {
        const {data}: { data: any } = await this.httpClient.post(
            `/commerce/create`, body
        )
        return data
    }


    public async getCommerceInfo(serverOptions: ServerOptions, search: any): Promise<any> {
        const {data}: { data: any } = await this.httpClient.get(`/commerce/info`, {
            params: {...serverOptions, search: {...search}},
        });
        return data;
    }

    public async changeStatusCommerce(body: { rut: string, is_activate: boolean }): Promise<any> {
        const {data}: { data: any } = await this.httpClient.put(`/commerce/status`, body);
        return data;
    }

    public async getSubscriptions(serverOptions: ServerOptions, search: any): Promise<SubscriptionList> {
        const {data}: { data: SubscriptionList } = await this.httpClient.get(`/subscription`, {
            params: {...serverOptions, search: {...search}},
        });
        return data;
    }

    public async getSubscriptionById(id: string): Promise<OrderById> {
        const {data}: { data: any } = await this.httpClient.get(
            `/subscription/id/${id}`
        );
        return data;
    }

    public async generateCharge(
        request: any
    ): Promise<any> {
        const {data}: { data: any } = await this.httpClient.post(`/subscription/generate-charge`, request);
        return data;
    }
}

const backend_url = window.location.host.replace('8080','3000');
const backend_url_parts = backend_url.split('.');
backend_url_parts.splice(-2, 0, "api");

const httpClient = new AxiosClient({
    baseURL: `${window.location.protocol}//${backend_url_parts.join('.')}`,
});

export const httpService = new HttpService(httpClient);
